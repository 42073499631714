@import url('https://fonts.googleapis.com/css?family=Bangers|Cinzel:400,700,900|Lato:100,300,400,700,900|Lobster|Lora:400,700|Mansalva|Muli:200,300,400,600,700,800,900|Open+Sans:300,400,600,700,800|Oswald:200,300,400,500,600,700|Roboto:100,300,400,500,700,900&display=swap');

h1, h2, h3, h4, h5, h6, p{
    margin:0px;
    padding:0px;
}

a {
    text-decoration: none;
}

.red_title{
    display: inline-block;
    color: red;
    font-family: "Times New Roman", Times, serif;
    font-size: 60px;
    letter-spacing: -4px;
    word-spacing: 0.2px;
    font-weight: 700;
    font-variant: small-caps;
    text-transform: none;
    text-decoration: none;
    -webkit-transition: all .3s;
    transition: all .3s;
}

.red_title:hover{
    color:#15171c;
    transition: all .3s;
    text-shadow: 5px -1px 3px #ff0000;
}

.main_nav_wrapper{
    position: fixed;
    top: 0;
    width: calc(100vw - 3em);
    display: flex;
    justify-content: space-between;
    height: 80px;
    padding: 0 2rem;
    background-color: white;
    color: black;
	align-items: center;
    border-bottom: 1px solid black;
    z-index: 5000;
}

.nav_link{
    color: black;
    text-decoration: none;
    transition: all 0.5s;
    font-family: 'Muli', sans-serif;
    font-weight:200;
    text-transform:uppercase;
    font-size:40px;
    color:#545454;
    line-height:0.6;
    margin: 0 1rem;
    font-size: 24px;
}

.nav_link:hover{
    color: cyan;
    font-size:32px;
}

.nav_btn{
    margin-top: 12px !important;
    cursor: pointer;
    background: transparent;
    border:none;
    outline: none;
    color: black !important;
    visibility: hidden;
    opacity: 0;
    display: none !important;
}

.main_nav_wrapper nav{
	display: flex;
	align-items: center;
}
@media only screen and (max-width: 1024px) {
    .red_title{
        font-size: min(14vw, 60px);
    }
    .nav_btn{
        visibility: visible;
        opacity: 1;
        display: inline !important;
        padding: 0;
    }
    .main_nav_wrapper{
        padding: 0 1rem;
    }
    .main_nav_wrapper nav{
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        height: 100%;
        width:100%;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        background-color: white;
        transition: 1s;
        transform: translateY(-120vh);
    }
    .responsive_nav{
        transform: none !important;
    }
    .nav_close_btn {
		position: absolute !important;
		top: 1rem;
		right: 1rem;
	}
    .nav_link{
        font-size: 3rem;
    }
  }

.nav-wrap {
    background-color: white;
    width: 300px;
    text-decoration: none !important;
    height: 80px;
    background: #fff;
    float: left;
    display: flex;
    justify-content: space-between;
}

.logo{
    color: white;
    display: flex;
}

p.para {
    text-align: center;
    font-size: 16px;
    margin-bottom: 20px;
    letter-spacing: 30px;
    font-family: 'Lora', serif;
    padding-top: 5px;
    color: #333333;
}

.cyan{
    color: cyan;
    display: flex;
}

.logo-holder{
    text-align:center;
    padding-top: 10px ;
    padding-left: 16px;
    padding-right: 16px;
}

.logo-10 h3{
    font-family: 'Muli', sans-serif;
    font-weight:200;
    text-transform:uppercase;
    font-size:40px;
    color:#545454;
    line-height:0.6;
}
.logo-10 p{
    color: rgba(255, 255, 255, 0);
    background:cyan;
}