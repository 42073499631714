@import url('https://fonts.googleapis.com/css?family=Bangers|Cinzel:400,700,900|Lato:100,300,400,700,900|Lobster|Lora:400,700|Mansalva|Muli:200,300,400,600,700,800,900|Open+Sans:300,400,600,700,800|Oswald:200,300,400,500,600,700|Roboto:100,300,400,500,700,900&display=swap');

.recipe-background{
    min-height: calc(100vh - 82px);
    width: 100vw;
    background-color: white;
    text-align: center;
}

.content-wrapper{
    max-width: 1000px;
    margin: auto;
    margin-top: calc(80px + 3em);
}

.linkage{
    text-decoration: none;
    color: cyan;
    transition: 0.5s ease-in-out;
}

.error-msg{
    margin-top: calc(82px + 1em);
}

.load-blur{
    width: 100vw;
    height: calc(100vh - 80px);
    position: fixed;
    top: 80px;
    z-index: 20;
    text-align: center;
    align-items: center;
    backdrop-filter: blur(5px);
}

.linkage:hover{
    font-size: 28px;
}

.loading-text{
    color: white;
    padding: 10px;
    font-size: 24px;
    text-decoration: none;
}

.loader-section{
    max-width: max(400px, 30vw);
    margin-top: 64px;
    margin-left: auto; 
    margin-right: auto; 
    padding: 10px;
    border-radius: 15px;
    background-color: rgb(61, 61, 61);
    cursor: pointer;
}

.loader-wrapper{
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.recipe-name{
    font-size: 28px;
    text-transform:uppercase;
    width: calc(100% - 115px);
    overflow-wrap: break-word;
}

.card-title-wrap{
    position: relative;
}

.share-btn{
    position: absolute !important;
    right: 0;
}

.prompt-text{
    color: black;
    text-decoration: none;
    transition: all 0.5s;
    font-family: 'Muli', sans-serif;
    font-weight:200;
    text-transform:uppercase;
    font-size:64px;
    color:#545454;
    line-height:0.8;
    margin: 0 1rem;
    padding-bottom: 0.5em;
}

.explanation-box{
    margin-top: 1em;
    text-align: center;
    white-space: pre-line;
    margin-bottom: 2em;
    margin-left: auto;
    margin-right: auto;
    max-height: fit-content !important;
    font-family: 'Muli', sans-serif;
    font-size: 18px;
    padding: 1em;
    max-width: 600px;
    background-color: white;
}

.recipe-wrap{
    margin-top: 1em;
    text-align: justify;
    white-space: pre-line;
    margin-bottom: 2em;
    max-height: fit-content !important;
    font-family: 'Muli', sans-serif;
    font-size: 18px;
    padding: 1em;
}

.name-box{
    min-width: min(400px, 100vw) !important;
    margin-bottom: 1em !important;
}

.loader {
    box-sizing: border-box;
    display: inline-block;
    width: 50px;
    height: 80px;
    border-top: 5px solid black;
    border-bottom: 5px solid black;
    position: relative;
    background: linear-gradient(cyan 30px, transparent 0) no-repeat;
    background-size: 2px 40px;
    background-position: 50% 0px;
    animation: spinx 5s linear infinite;
}
.loader:before, .loader:after {
    content: "";
    width: 40px;
    left: 50%;
    height: 35px;
    position: absolute;
    top: 0;
    transform: translatex(-50%);
    background: rgba(255, 255, 255, 0.4);
    border-radius: 0 0 20px 20px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: 0 0px;
    animation: lqt 5s linear infinite;
}
.loader:after {
    top: auto;
    bottom: 0;
    border-radius: 20px 20px 0 0;
    animation: lqb 5s linear infinite;
}
@keyframes lqt {
0%, 100% {
    background-image: linear-gradient(cyan 40px, transparent 0);
    background-position: 0% 0px;
}
50% {
    background-image: linear-gradient(cyan 40px, transparent 0);
    background-position: 0% 40px;
}
50.1% {
    background-image: linear-gradient(cyan 40px, transparent 0);
    background-position: 0% -40px;
}
}
@keyframes lqb {
0% {
    background-image: linear-gradient(cyan 40px, transparent 0);
    background-position: 0 40px;
}
100% {
    background-image: linear-gradient(cyan 40px, transparent 0);
    background-position: 0 -40px;
}
}
@keyframes spinx {
0%, 49% {
    transform: rotate(0deg);
    background-position: 50% 36px;
}
51%, 98% {
    transform: rotate(180deg);
    background-position: 50% 4px;
}
100% {
    transform: rotate(360deg);
    background-position: 50% 36px;
}
}

@media only screen and (max-width: 800px){
    .prompt-text{
        font-size: 48px;
    }
}
      
     